import React from "react"
import { Link } from "gatsby"
import {
  Section,
  Container,
  Title,
  Subtitle,
  Button,
  Icon,
  Column,
  Columns,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LeapcodePage = () => (
  <Layout>
    <SEO title="Leapcode" />
    <Section className="has-background-white">
      <Container>
        <Columns >
          <Column style={{ margin: '0 auto' }} isSize="3/4">
            {/* Page title */}
            <Title isSpaced><a href="https://women.leapcode.io" target="_blank">
              Leapcode Women</a>
            </Title>
            <Subtitle>A Community for Women in Tech</Subtitle>
            <p>I've had the pleasure of meeting a lot of inspiring women in the past few years especially at tech conferences. Listening to their inspiring stories and realizing their contribution to the tech world has always brought out the best in me. It has also helped me to step out of my comfort zone and work on versatile projects. These experiences triggered a thought, building a platform to share our stories, experiences and opportunities that are out there for the grabs.
<br></br>
              <br></br>
That’s when I decided to build Leapcode Women to inspire every woman in tech in India. Whether you are looking to start your the first job in tech, enroll for a course, switch from a non-tech background to a tech role or looking to specialize in a domain, we aim to bring all these stories & conversations together and help each other.👭🏽</p>
            <br></br>


            <div style={{ margin: '20px 0' }}>
              <Columns>
                <Column isSize="1/2">
                  <div className=" inline-block xs-mb2 lg-pb2 md-pb2 xs-pb1 lg-flex-auto items-center center"><blockquote class="twitter-tweet"><p lang="en" dir="ltr">I recently became a part of <a href="https://twitter.com/leapcodeio?ref_src=twsrc%5Etfw">@leapcodeio</a> and wrote my first post. It&#39;s a wonderful online platform for women in Tech by <a href="https://twitter.com/isethu?ref_src=twsrc%5Etfw">@isethu</a> to share experiences, good practices and interview tips. <a href="https://t.co/dcmYRkqvVo">https://t.co/dcmYRkqvVo</a></p>&mdash; Shrreya Behll (@behll_shrreya) <a href="https://twitter.com/behll_shrreya/status/1206801497255636993?ref_src=twsrc%5Etfw">December 17, 2019</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></div>
                </Column>

                <Column isSize="1/2">
                  <div className="inline-block xs-mb2 lg-pb2 md-pb2 xs-pb1 lg-flex-auto items-center"> <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Scholarships and programs for women in tech <a href="https://t.co/sqpBbrezpE">https://t.co/sqpBbrezpE</a> by <a href="https://twitter.com/isethu?ref_src=twsrc%5Etfw">@isethu</a> on <a href="https://twitter.com/leapcodeio?ref_src=twsrc%5Etfw">@leapcodeio</a> <a href="https://twitter.com/hashtag/womenintech?src=hash&amp;ref_src=twsrc%5Etfw">#womenintech</a></p>&mdash; Tushar Sonawane (@Tushkiz) <a href="https://twitter.com/Tushkiz/status/1206444771364884480?ref_src=twsrc%5Etfw">December 16, 2019</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></div>
                </Column>
              </Columns>
            </div>



            <div className="flex flex-row align-center md-justify-between lg-justify-between xs-justify-center flex-wrap max-width-4 mx-auto">
            </div>
            <div style={{ margin: '20px 0 50px' }}>
              <div>Featured on</div>
              <div>
                <a href="https://epaper.thehindu.com/Home/ShareArticle?OrgId=GI16NS33J.1&imageview=1" target="_blank"> <img style={{ width: "150px" }} src={'/hindu.png'} /></a>
              </div>
            </div>
            {/* Back to homepage button */}
            <Link to="/">
              <Button isColor="primary" className="is-rounded" id="is-spaced">
                <Icon className="fa fa-arrow-left fa-sm" />
                <span>Go back</span>
              </Button>
            </Link>
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)

export default LeapcodePage
